






















































































































import {
  defineComponent,
  ref,
  computed,
  useFetch,
  useRoute,
  watch, onMounted
} from '@nuxtjs/composition-api';
import {
  CmsBlock,
  useCart,
  useWishlist
} from '~/composables';
import {
  useUser,
  useContent
} from '~/composables';
import TopBar from '~/components/Palmers/TopBar/TopBar.vue';
import Header from "~/components/Palmers/Header/Header.vue";
import cmsGetters from "~/modules/palmers/cms/getters/cmsGetters";
import {useCategoryStore} from "~/modules/catalog/category/stores/category";
import {useWishlistStore} from "~/modules/wishlist/store/wishlistStore";
import cartGetters from "~/modules/checkout/getters/cartGetters";
import wishlistGetters from '~/modules/wishlist/getters/wishlistGetters';
import {useUiState} from '~/composables';
import {
  useAddToCartPopup,
  useLoggedWatcher,
  useUrl
} from "~/composables/Palmers";
import {useAttributesStore} from "~/stores/attributes";
import StaticBlock from '~/components/Palmers/BaseComponents/Cms/StaticBlock.vue';
import {DelayHydration} from 'nuxt-delay-hydration/dist/components'
import {useConfigStore} from "~/stores/config";
import type {MetaInfo} from "vue-meta";
import useTheme from "~/composables/useTheme";
import RunningNews from "~/components/Palmers/PageBuilder/Element/RunningNews.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    DelayHydration,
    LoadWhenVisible,
    CartSidebar: () => import('~/components/Palmers/Cart/CartSidebar.vue'),
    StaticBlock,
    TopBar,
    RunningNews,
    LoaderFrame: () => import("~/components/Palmers/BaseComponents/Loaders/LoaderFrame.vue"),
    Header,
    Footer: () => import("~/components/Palmers/Footer/Footer.vue"),
    AddToCartModal: () => import("~/components/Palmers/Product/Actions/AddToCartModal.vue"),
    Notification: () => import('~/components/Palmers/BaseComponents/Notification/Notification.vue'),
    GTM: () => import('~/components/Palmers/GTM.vue'),

    palmers: () => import('~/components/Palmers/GeneralStyles.vue'),
    palmers_new: () => import('~/components/Palmers_new/GeneralStyles.vue')
  },
  setup() {
    const {
      isCartSidebarOpen,
    } = useUiState();

    const {getCurrentTheme} = useTheme();

    const attributes = useAttributesStore();

    useLoggedWatcher();

    const {product, visible, deactivateModal, configuration} = useAddToCartPopup();

    const {getCategoryUrl} = useUrl();

    const loaded = ref(false);

    const staticBlocksIds = [
      'top_action_links',
      'footer_bottom_with_payments',
      'footer_block_with_socials',
      'header_promotion_bar',
      'footer_palmers_club',
      'footer_column_1',
      'footer_column_2',
      'footer_column_3',
      'footer_column_4',
      'bottom_action_links',
      'running_news'
    ]

    const {
      isAuthenticated,
    } = useUser();

    const {
      addOrRemoveItem,
      isInWishlist
    } = useWishlist();


    const {
      cart,
      addItem: addProductToCart,
      isInCart: isProductInCart,
      canAddToCart
    } = useCart();

    const wishlistStore = useWishlistStore();

    const cartItemsCount = computed(() => cartGetters.getTotalItems(cart.value));

    const wishlistItemsCount = computed(() => wishlistGetters.getTotalItems(wishlistStore.wishlist));

    const {
      loadBlocks,
    } = useContent();

    const categories = useCategoryStore();

    const categoryTree = computed(() => categories.categories?.children
      .filter((category) => category.include_in_menu));

    const staticBlocks = ref<CmsBlock[]>([]);

    const {loadItemsCount} = useWishlist();

    onMounted(async () => {
      const promises = [
        loadBlocks({identifiers: staticBlocksIds}),
        loadItemsCount()
      ];

      if (attributes.attributesData === null) {
        //@ts-ignore
        promises.push(attributes.load());
      }

      if (categories.categories === null) {
        //@ts-ignore
        promises.push(categories.load());
      }

      //@ts-ignore
      const loadedData = await Promise.all(promises);

      //@ts-ignore
      staticBlocks.value = loadedData[0];

      loaded.value = true;
    })

    const getStaticBlockById = (id) => {
      return cmsGetters.getStaticBlock(staticBlocks.value, id);
    }

    const route = useRoute();

    const isCheckout = computed(() => route.value?.matched?.length > 1 && route.value?.matched?.[0].name.indexOf('checkout') > -1);

    watch(route, () => {
      deactivateModal();
    })

    const configStore = useConfigStore();
    const {storeConfig} = configStore;


    const getHead = () => {
      const scripts = [];

      if (storeConfig) {
        const {
          //@ts-ignore
          cookie_pro_general_active,
          //@ts-ignore
          cookie_pro_general_cookie_pro_id,
          //@ts-ignore
          cookie_pro_general_script_url,
          //@ts-ignore
          cookie_pro_general_sdk_script_url
        } = storeConfig;

        if (
          cookie_pro_general_active
          && cookie_pro_general_active === '1'
          && cookie_pro_general_cookie_pro_id
          && cookie_pro_general_script_url
          && cookie_pro_general_sdk_script_url
        ) {
          scripts.push(
            {
              hid: 'cookie-pro-1',
              type: 'text/javascript',
              src: cookie_pro_general_script_url,
              once: true,
              body: false,
              defer: true
            },
            {
              hid: 'cookie-pro-2',
              type: 'text/javascript',
              charset: 'UTF-8',
              'data-document-language': 'true',
              'data-domain-script': cookie_pro_general_cookie_pro_id,
              src: cookie_pro_general_sdk_script_url,
              once: true,
              body: false,
              defer: true
            }
          )
        }
      }

      return {
        script: scripts
      };
    }

    return {
      addProductToCart,
      isProductInCart,
      isCartSidebarOpen,
      getStaticBlockById,
      staticBlocks,
      categoryTree,
      getCategoryUrl,
      cartItemsCount,
      wishlistItemsCount,
      isAuthenticated,
      product,
      visible,
      deactivateModal,
      canAddToCart,
      configuration,
      addOrRemoveItem,
      isInWishlist,
      route,
      isCheckout,
      getHead,
      getCurrentTheme
    };
  },
  head() {
    //@ts-ignore
    return this.getHead() as MetaInfo;
  }
});
