import { defineStore } from 'pinia';
import { CategoryResult } from '~/composables';

interface CategoryState {
  rawCategories: CategoryResult | null;
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
  }),
  actions: {
    async load() {
      try {
        //@ts-ignore
        const { data }: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.categoriesList();
        this.rawCategories = data?.categories || null;
      } catch (error) {
        console.error("Error loading categories:", error);
      }
    },
  },
  getters: {
    categories(state) {
      return state.rawCategories?.items[0] || null;
    },
  },
});
