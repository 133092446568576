






import { defineComponent, ref, onMounted, onBeforeUnmount, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ElementRunningNews',
  props: {
    element: {
      type: Object,
      require: true
    }
  },
  setup(props) {
    const marquee = ref(null);
    const text = props.element.news_title;
    const repeatCount = ref(20);
    // const repeatedText = computed(() => {
    //   return Array(repeatCount.value).fill(`<span>${text}</span>`).join("");
    // });
    const repeatedText = ref(Array(repeatCount.value).fill(`<span>${text}</span>`).join(""))


    // const calculateRepeats = () => {
    //   if (marquee.value) {
    //     const containerWidth = marquee.value.offsetWidth;
    //     const textWidth = getTextWidth(text, marquee.value);
    //     if (textWidth > 0) {
    //       repeatCount.value = Math.ceil(containerWidth / textWidth) * 2;
    //     }
    //   }
    // };

    const getTextWidth = (text, element) => {
      const context = document.createElement('canvas').getContext('2d');
      const style = window.getComputedStyle(element);
      context.font = `${style.fontSize} ${style.fontFamily}`;
      return context.measureText(text).width;
    };

    // onMounted(() => {
    //   calculateRepeats();
    //   window.addEventListener('resize', calculateRepeats);
    // });
    //
    // onBeforeUnmount(() => {
    //   window.removeEventListener('resize', calculateRepeats);
    // });

    return {
      marquee,
      repeatedText,
      marqueeStyle: computed(() => ({
        'animation-duration': `${repeatCount.value / 2 * 10}s`,
        'background-color': props.element.news_background,
        'color': props.element.news_title_color
      }))
    };
  }
});
