

























import {defineComponent} from '@nuxtjs/composition-api';
import {useUiState} from '~/composables';
import {useUrl} from "~/composables/Palmers";

export default defineComponent({
  name: "HeaderActions",
  props: {
    isAuthenticated: {
      type: Boolean,
      require: true
    },
    wishlistItemsCount: {
      type: Number,
      require: true
    },
    cartItemsCount: {
      type: Number,
      require: true
    }
  },

  setup() {
    const {toggleCartSidebar} = useUiState();
    const {getPageUrl} = useUrl();

    return {
      toggleCartSidebar,
      getPageUrl
    }
  },
  methods: {
    clickToCart() {
      //@ts-ignore
      if (this.$device.isDesktop) {
        this.toggleCartSidebar();
      } else {
        this.$router.push(this.getPageUrl({name: 'cart'}));
      }
    }
  }
});

