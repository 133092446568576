








import {defineComponent} from '@nuxtjs/composition-api';
import useTopBar from '~/components/TopBar/useTopBar';

export default defineComponent({
  name: "HeaderInfoMenu",
  components: {
    StoreSwitcher: () => import('~/components/Palmers/Stores/StoreSwitcher.vue'),
    StaticBlock: () => import(/* webpackMode: "lazy" */ '~/components/Palmers/BaseComponents/Cms/StaticBlock.vue'),
  },
  props: {
    topActionLinks: {
      type: [String, Boolean, Object],
      require: true
    }
  },

  setup() {
    const { hasStoresToSelect } = useTopBar();

    return {
      hasStoresToSelect,
    };
  },

});

