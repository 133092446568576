

































































































import {defineComponent} from '@nuxtjs/composition-api';
import {useUrl} from "~/composables/Palmers";
import HeaderInfoMenu from "~/components/Palmers/Header/HeaderInfoMenu.vue";
import HeaderActions from "~/components/Palmers/Header/HeaderActions.vue";
export default defineComponent({
  name: "Header",
  components: {
    HeaderInfoMenu,
    HeaderMenu: () => import("~/components/Palmers/Header/HeaderMenu.vue"),
    HeaderMenuMobile: () => import("~/components/Palmers/Header/HeaderMenuMobile.vue"),
    HeaderActions,
    HeaderPromotionBlock: () => import("~/components/Palmers/BaseComponents/Promotion/HeaderPromotionBlock.vue"),
    FreeShippingBar: () => import("~/components/Palmers/BaseComponents/Promotion/FreeShippingBar.vue")
  },
  props: {
    promotionBlock: {
      type: [Object, Boolean],
      require: true
    },
    categoryTree: {
      type: Array,
      require: true
    },
    topActionLinks: {
      type: [Object, Boolean],
      require: true
    },
    getCatLink: {
      type: Function,
      require: true
    },
    isAuthenticated: {
      type: Boolean,
      require: true
    },
    wishlistItemsCount: {
      type: Number,
      require: true
    },
    cartItemsCount: {
      type: Number,
      require: true
    },
    addProductToCart: {
      type: Function,
      require: true
    },
    isProductInCart: {
      type: Function,
      require: true
    },
    isProductInWishlist: {
      type: Function,
      require: true
    },
    canAddProductToCart: {
      type: Function,
      require: true
    },
    addOrRemoveItemFromWishlist: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      menuOpen: false,
      isScrolled: false,
    }
  },
  setup() {
    const {getPageUrl} = useUrl();

    return {
      getPageUrl
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    handleScroll() {
      const top = window.scrollY || window.pageYOffset;
      this.isScrolled = top > 0;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
});
